 @charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:        bountypress
Version:        2.0
Last change:    02.20.2016
Primary use:    Attorney & Lawyer Business Template
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/

// Import bootstrap variables and mixins for reference
@import (reference) "less-bootstrap/variables.less";
@import (reference) "less-bootstrap/mixins.less";

//fonts
@import "../css/font-awesome.min.css";
@import "../css/font-awesome-animation.min.css";
@import "../css/pe-icon-7-stroke.css";
/* @import "../css/elegant-icons.css"; */
/* @import "../css/stroke-gap-icons.css"; */
@import "../css/flaticon-set-charity.css";
@import "../css/utility-classes.css";
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900|Raleway:200,300,400,500,600,700,800|Trirong:100,200,300,400,500,600,700,800,900);

 
// Table of contents
@import "less-bountypress/table-of-content.less";

 
// Initialize Variables
@import "less-bountypress/variables.less";
@import "less-bountypress/mixins.less";
// Typography
@import "less-bountypress/typography.less";

// Common Styles
@import "less-bountypress/common.less";
@import "less-bountypress/extra.less";
@import "less-bountypress/overlay.less";

// Header
@import "less-bountypress/header.less";

// Nav
@import "less-bountypress/nav.less";

// Content Blocks
@import "less-bountypress/topbar.less";
@import "less-bountypress/inner-header-title.less";
@import "less-bountypress/vertical-nav.less";
@import "less-bountypress/menu-full-page.less";
@import "less-bountypress/boxed-layout.less";
@import "less-bountypress/form.less";
@import "less-bountypress/side-push-panel.less";
@import "less-bountypress/box-hover-effect.less";
@import "less-bountypress/gallery-isotope.less";
@import "less-bountypress/sliders.less";
@import "less-bountypress/home.less";
@import "less-bountypress/about.less";
@import "less-bountypress/causes.less";
@import "less-bountypress/volunteer.less";
@import "less-bountypress/contact.less";
@import "less-bountypress/event.less";
@import "less-bountypress/practice-area.less";
@import "less-bountypress/services.less";
@import "less-bountypress/job.less";
@import "less-bountypress/shop.less";
@import "less-bountypress/blog.less";

// Shortcodes
@import "less-bountypress/shortcodes.less";


// Widgets
@import "less-bountypress/widgets.less";


// Footer
@import "less-bountypress/footer.less";